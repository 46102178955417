import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";

import * as styles from "./styles.module.scss";

const SanityVideo = ({data = {}, playVideo, theme}) => {
  const {type, showThumbnail, ytId, file, image} = data;
  const styler = new StyleExtractor(styles, theme);
  const {asset: {url: videoUrl} = {}} = file || {};
  const gatsbyImageData = image?.asset?.gatsbyImageData;
  const imageUrl = image?.asset?.imageUrl;
  const {alt = "", name = ""} = image || {};

  return (
    <div className={styler.get("container")}>
      {!playVideo && showThumbnail ? (
        <GatsbyImage
          image={gatsbyImageData}
          objectFit='contain'
          alt={alt || name}
        />
      ) : type === "upload" ? (
        <video
          controls
          autoPlay={showThumbnail}
          playsInline
          src={videoUrl}
          style={{
            aspectRatio: `${gatsbyImageData?.width} / ${gatsbyImageData?.height}`,
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      ) : (
        <iframe
          style={{
            aspectRatio: `${gatsbyImageData?.width} / ${gatsbyImageData?.height}`,
            backgroundImage: `url(${imageUrl})`,
          }}
          title='YouTube video player'
          src={`https://www.youtube.com/embed/${ytId}?autoplay=${
            showThumbnail ? 1 : 0
          }`}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen='1'
          frameBorder='0'
        />
      )}
    </div>
  );
};
export default SanityVideo;
