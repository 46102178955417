import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Play from "react-feather/dist/icons/play";

import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import {emailCapture} from "../../../../utils/emailCapture";
import MMCta from "../../../commons/MMCTA";
import SanityRichText from "../../../commons/SanityRichText";
import SanityVideo from "../../../commons/SanityVideo";
import * as styles from "./styles.module.scss";

const MMHero = ({
  data,
  v3,
  marketingEmailCapture = false,
  theme = {},
  source = "ModeMagicPage",
}) => {
  const styler = new StyleExtractor(styles, theme);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [playVideo, setPlayVideo] = React.useState(false);
  const {
    _rawHeading: heading,
    cta: {
      text: ctaText,
      responseText: ctaResponse,
      mobileRedirectUrl,
      // action: ctaAction,
      url: ctaURL,
    } = {},
    image,
    alt,
    mobileImage,
    mobileAlt,
    _rawDescription: description,
    _rawMobileDescription: mobileDescription,
    video,
    show,
    _rawAuditCtaText: auditCTAText = [],
    auditCTA = null,
  } = data ?? {};

  const customClickHandler = (email) => {
    if (marketingEmailCapture) {
      return emailCapture({email}, ctaURL);
    }
    if (v3) {
      return () => {
        window.location.href = ctaURL;
      };
    }
  };

  return (
    <div className={styler.get("heroWrap", "full-bleed layouted")}>
      <div
        className={styler.get(
          ["heroGrid", v3 && "v3"],
          v3
            ? "md:mt-36 pt-4 md:pt-8 md:pt-0 pb-0 md:pb-24 md:mb-0"
            : "md:mt-36 pt-4 md:pt-0 md:mb-0"
        )}
      >
        <SanityRichText
          renderContainerOnSingleChild
          blocks={heading}
          className={styler.get("heading")}
        />
        {!v3 && <br />}
        <SanityRichText
          renderContainerOnSingleChild
          className={styler.get("heroContent")}
          blocks={isMobile ? mobileDescription : description}
        />
        {!v3 && <br />}
        <div
          className={styler.get(
            "heroCta",
            "flex flex-col md:flex-row text-center md:items-center"
          )}
        >
          <MMCta
            showEmail={marketingEmailCapture}
            showLoading={marketingEmailCapture}
            cta={ctaText}
            href={ctaURL}
            emailResponse={ctaResponse}
            mobileRedirectUrl={mobileRedirectUrl}
            id='mm-install-app-hpherocta'
            showResponse={isMobile}
            hideEmailInMobile={v3}
            v3={v3}
            isHeroCTA
            customClickHandler={!marketingEmailCapture && customClickHandler}
            source={source}
            auditCTAText={auditCTAText}
            auditCTAURL={auditCTA?.url ?? ""}
          />
        </div>
        {show !== "video" ? (
          <>
            <GatsbyImage
              className={styler.get(["heroImage", "hideOnDesktop"])}
              image={
                mobileImage?.asset?.gatsbyImageData ||
                image?.asset?.gatsbyImageData
              }
              alt={isMobile ? mobileAlt : alt || "hero image"}
              objectFit='contain'
            />
            <GatsbyImage
              className={styler.get(["heroImage", "hideOnMobile"])}
              image={image?.asset?.gatsbyImageData}
              alt={isMobile ? mobileAlt : alt || "hero image"}
              objectFit='contain'
            />
          </>
        ) : (
          <div className={styler.get("heroImage")}>
            <div className={styler.get("heroImageWrapper")}>
              <SanityVideo data={video} playVideo={playVideo} />
              <div
                className={styler.get("playBtn")}
                onClick={() => setPlayVideo(!playVideo)}
              >
                <Play /> Play
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MMHero;
