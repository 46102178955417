import {StyleExtractor, isValidString} from "@kubric/utils";
import React from "react";

import MMCta, {AuditPageCTA} from "../../../commons/MMCTA";
import * as styles from "./styles.module.scss";

const StickyCTA = ({
  data,
  className,
  alternateCta,
  isEmailCTA,
  mobileCTAText = "",
  mobileCTAUrl = "",
  onClick = () => {},
  darkMode = false,
  ...props
}) => {
  const styler = new StyleExtractor(styles);
  const {text: ctaText, url: ctaURL} = data || {};

  const [responseVisible, setResponseVisible] = React.useState(false);

  return (
    <div
      onClick={onClick}
      className={styler.get(
        [
          darkMode ? "darkStickyCTA" : "sticyCta",
          alternateCta && "alternate",
          isEmailCTA && "emailCTA",
          responseVisible && "responseContainer",
        ],
        className
      )}
    >
      <MMCta
        cta={ctaText}
        href={ctaURL || undefined}
        id='install-mm-app'
        v3
        footer={false}
        hideEmailInMobile
        theme={{cta: styler.get(["cta"])}}
        showResponse
        alternateResponse
        showLoading
        onResponseVisible={() => setResponseVisible(true)}
        onResponseCTAClick={() => setResponseVisible(false)}
        {...props}
      />
      {isValidString(mobileCTAText) && (
        <AuditPageCTA
          mobileCTAUrl={mobileCTAUrl}
          mobileCTAText={mobileCTAText}
          isMobile
        />
      )}
    </div>
  );
};

export default StickyCTA;
