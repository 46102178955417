// extracted by mini-css-extract-plugin
export var cta = "styles-module--cta--20OqU";
export var heading = "styles-module--heading--5UArS";
export var heroContent = "styles-module--heroContent--3ltZA";
export var heroCta = "styles-module--heroCta--2g-7n";
export var heroGrid = "styles-module--heroGrid--uCkuU";
export var heroImage = "styles-module--heroImage--W-CUW";
export var heroImageWrapper = "styles-module--heroImageWrapper--1yDDv";
export var heroWrap = "styles-module--heroWrap--3VMoU";
export var hideOnDesktop = "styles-module--hideOnDesktop--1djq1";
export var hideOnMobile = "styles-module--hideOnMobile--pYj-e";
export var left = "styles-module--left--1FivT";
export var playBtn = "styles-module--playBtn--2yLQx";
export var v3 = "styles-module--v3--3nTVO";