import "regenerator-runtime/runtime";

const axios = require("axios").default;

// to capture emails for marketing analysis
export const emailCapture = async (data, redirect) => {
  try {
    await axios
      .put("/api/emailCapture", {
        ...data,
        env: process.env.GATSBY_ENV,
      })
      .catch((e) => console.debug(e))
      .finally(() => {
        if (redirect) {
          window.location.href = redirect;
        }
      });
  } catch (e) {
    console.debug(e);
  }
};
